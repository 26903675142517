/* pricing table */
.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
    
    tr {

        /* Highlighted column */
        &:nth-child(2n) td:nth-child(3) {
            background-color: rgba(216, 214, 227, 0.25);
        }

        &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        }

        &:nth-child(even) {
            background-color: #FFFFFF;
        }

        &:hover {
            background-color: #EEEEEE;
        }

        td {
            border-left: 1px solid rgba(0, 0, 0, 0.05);
            padding: 8px 24px;
            font-size: 14px;
            
            &.price-table-popular {
                font-family: "Montserrat";
                border-top: 3px solid $theme-color;
                color: $theme-color;
                text-transform: uppercase;
                font-size: 12px;
                padding: 12px 48px;
                font-weight: 700;
            }

            &.price {
                color: #f43f54;
                padding: 16px 24px;
                font-size: 20px;
                font-weight: 600;
                font-family: "Montserrat";
            
                p {
                    width: 13rem;
                    color: #9f9f9f;
                }

                a {
                    background-color: $theme-color;
                    color: #FFFFFF;
                    padding: 12px 32px;
                    margin-top: 16px;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: "Montserrat";
                    text-transform: uppercase;
                    display: inline-block;
                    border-radius: 64px;
                }
            }

            &:first-child {
                border-left: 0 none;
            }

            &:not(:first-child) {
                text-align: center;
            }

            &:nth-child(3) {
                background-color: rgba(216, 214, 227, 0.15);
                padding: 8px 48px;
            }
        }

        &.price-table-head td {
            font-size: 16px;
            font-weight: 600;
            font-family: "Montserrat";
            text-transform: uppercase;
        }
        
        &.price-table-head {
            background-color: $theme-color;
            color: #FFFFFF;
        }
    }

    .fa-check {
        color: $theme-color;
    }

    .fa-times {
        color: #D8D6E3;
    }
    
    .price-blank {
        background-color: #fafafa;
        border: 0 none;
    }

    svg {
        width: 90px;
        fill: $theme-color;
    }
    
}