.styled-input {
  float: left;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
}
.dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  height: 200px;
}
.styled-input.multi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
  height: auto;
}

.styled-input label {
  color: #9b9b9b;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.92px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  -o-transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
}

.styled-input.multi label {
  padding: 10px 0 0;
}

.styled-input.active {
  border: 1px solid #d0e5ba;
  -webkit-box-shadow: inset 0 -2px 4px 0 #d5eebb;
  box-shadow: inset 0 -2px 4px 0 #d5eebb;
}

.styled-input .icon--check,
.styled-input .icon--error,
.styled-input .chevron-down {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.styled-input .icon--check,
.styled-input .icon--error {
  display: none;
}

.styled-input .icon--check {
  right: 0%;
}

.styled-input input.success ~ .icon--check,
.styled-input input.error ~ .icon--error {
  display: inline-block;
}

.styled-input label.error,
.styled-input input:focus ~ label.error,
.styled-input input#fn:valid ~ label.error,
.styled-input input#ln:valid ~ label.error {
  font-size: 15px;
  text-transform: none;
  letter-spacing: normal;
  color: #ff523d;
  top: 53px;
  left: -3px;
}

.styled-input.multi.error {
  margin-bottom: 20px;
}

.styled-input.multi > div {
  position: relative;
  width: 100%;
  border-right: 2px solid #ccc;
}

.styled-input.multi > div:nth-last-of-type(1) {
  border-right: 0;
}

.styled-input.multi > div input,
.styled-input.multi > div label {
  padding-left: 12px;
}

.styled-input.multi > div input {
  padding-top: 20px;
}

.styled-input input:focus,
.styled-input textarea:focus,
.styled-input select:focus {
  outline: none;
}

.styled-input input,
.styled-input textarea,
.styled-input select {
  color: #4a4a4a;
  border: 0;
  width: 90%;
  font-size: 18px;
  padding-top: 20px;
  background: transparent;
}

.styled-input select {
  width: 100%;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  z-index: 1;
  left: 11px;
}

/* Focus Label */

.styled-input input:focus ~ label,
.styled-input input#fn:valid ~ label,
.styled-input input#ln:valid ~ label,
.styled-input input#city[filled="true"]:valid ~ label,
.styled-input #select-state label {
  font-size: 13px;
  letter-spacing: 1.56px;
  color: #4a3b8f;
  top: -12.8px;
  -webkit-transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
  -o-transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
  transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
}

@media (min-width: 991px) {
  .styled-input.multi {
    height: 70px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .styled-input.multi {
    height: 60px;
    padding: 8px 0;
  }
  .styled-input.multi > div input {
    padding-top: 18px;
  }
  .styled-input.multi label {
    padding: 7px 0 0;
  }
  .styled-input input#fn:valid ~ label,
  .styled-input input#ln:valid ~ label,
  .styled-input input#city[filled="true"]:valid ~ label,
  .styled-input.multi input:focus ~ label,
  .styled-input #select-state label {
    top: -9px;
  }
  .styled-input.multi.error {
    margin-bottom: 30px;
  }
  .styled-input label.error,
  .styled-input input:focus ~ label.error,
  .styled-input input#fn:valid ~ label.error,
  .styled-input input#ln:valid ~ label.error {
    font-size: 13px;
    top: 53px;
  }
  .search-area .form-area button.serach-btn {
    height: 60px;
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .examples [class^="col-"] {
    padding: 0;
  }

  .styled-input.multi > div {
    background-color: #fff;
    margin-bottom: 10px;
    display: block;
    border: 1px solid #efefef;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    padding: 16px 16px 8px 11.2px;
    height: 50px;
  }

  .styled-input.multi {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    margin-bottom: 0;
  }

  .styled-input.multi > div label {
    padding: 12px 0 0 12px;
  }
  .styled-input.multi > div input:valid ~ label,
  .styled-input.multi > div input:focus ~ label,
  .styled-input.multi #select-state > label {
    padding-top: 15px;
  }

  .styled-input.multi > div input {
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    z-index: 2;
    width: 100%;
  }
  .styled-input select {
    left: 0;
    padding-top: 2px;
  }

  .styled-input.multi.error {
    margin-bottom: 0;
  }

  .styled-input.multi > div.error {
    margin-bottom: 30px;
  }

  .styled-input.multi > div.error label.error {
    padding-top: 0;
  }
}

/* Button */

.no-pad-left-10 {
  padding-left: 5px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .no-pad-left-10 {
    padding-left: 0px;
    margin-left: -5px;
    width: calc(16.66666667% + 5px);
  }
}

@media (max-width: 767px) {
  .no-pad-left-10 {
    padding-left: 15px;
    margin-left: 0;
  }
}

.form-area button.serach-btn {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: none;
  font-size: 24px;
  background: #81b44c;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.01px;
  width: 100%;
  height: 70px;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
}

.form-area button.serach-btn:hover {
  background: #638a3a;
  -webkit-box-shadow: -2px -2px 4px -4px rgba(0, 0, 0, 0.02),
    0 3px 9px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  box-shadow: -2px -2px 4px -4px rgba(0, 0, 0, 0.02),
    0 3px 9px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.form-area button.serach-btn:focus {
  outline: none;
}

@media only screen and (max-width: 991px) {
  .form-area button.serach-btn {
    font-size: 20px;
    height: 60px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .form-area button.serach-btn {
    font-size: 22px;
    padding: 7px 20px;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    margin-top: -12px;
    margin-bottom: 20px;
  }
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: #000;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  color: #000;
}

/* .bg-hardcore {
  position: relative;
  background-image: url(assets/img/hardcore.jpg);
  background-size: cover;
  background-position: center;
} */

.bg-hardcore::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2); /* adjust opacity as needed */
  filter: brightness(0.1);
}
.city-bx,
.city-bx:after {
  border-radius: 4px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.city-bx {
  height: 180px;
  background-size: cover;
  z-index: 1;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
}
.city-info {
  position: relative;
  z-index: 1;
  color: #fff;
  padding: 20px 25px;
}
.city-info h5 {
  font-weight: bold;
  font-size: 22px;
  color: #fff;
  margin-bottom: 5px;
}
.city-bx:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000930;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.input-group-addon {
  padding: 11px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group-addon i {
  color: #ccc;
}

.form-control {
  padding-left: 30px;
}

.form-control:focus + .input-group-addon i {
  color: #555;
}
