.Probtn2 {
  color: green;
  font-weight: 500;
}
.Probtn2:hover {
  background-color: #d8f0e4 !important;
  color: green;
}
.ProfileDiv2 {
  width: 20%;
  position: relative;
}
.FiCmare2 {
  position: absolute;
  font-size: xx-large;
  left: 70%;
  top: 70%;
  background-color: white;
  width: 35px;
}
.BsPlay2 {
  position: absolute;
  font-size: xx-large;
  left: 45%;
  top: 25%;
  background-color: white;
  border-radius: 50%;
  font-size: 42px;
}
.ProLock1 {
  position: absolute;
  left: 21.2%;
  top: 65%;
  background-color: white;
}

@media (max-width: 425px) {
  .FiCmare2 {
    left: 30%;
  }
}
@media (max-width: 375px) {
  .FiCmare2 {
    left: 25%;
  }
}
@media (max-width: 320px) {
  .FiCmare2 {
    left: 21%;
  }
}

.wrappering {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  padding: 20px 25px 40px;
  box-shadow: 0 12px 35px rgba(0, 0, 0, 0.1);
}

.range-input {
  position: relative;
}
.range-input input {
  position: absolute;
  width: 100%;
  height: 8px;
  top: -5px;
  background: #fff;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  z-index: 1000;
}
input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #17a2b8;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
input[type="range"]::-moz-range-thumb {
  height: 50rem;
  width: 50px;
  border: none;
  border-radius: 50%;
  background: #17a2b8;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
