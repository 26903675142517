/* .uploadfile-div {
    position: relative;
    display: flex;
    top: 30px;
    justify-content:flex-start;
    margin-bottom: 20%;
    margin-top: 100px;
}

.uploadfile-div img {
    position: absolute;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    box-shadow: #000 0px 5px 5px;
}

input[type="file"] {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
}

 */

 .profile-image{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: 140px;
    box-shadow: #000 0px 5px 5px;
 }

 .hr-line {
    /* margin: 0 5% 0 5%; */
    border-top: 2px solid #eeeeee;
    width: 90%;
  }
.Profileimg1{
    position:absolute;
    left:140px;
    top:150px;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 70%;
    background-color: gray;
    color: white;
    font-size: 30px;
}

  /* owais */
  .UserProfileImage2{
    background-image: url(../../../assets/img/bg.jpg);
    height: 300px;
    width: 100%;
    background-size: 100% 100%;
  }